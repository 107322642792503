<ion-row class="stickyHeader">
	<ion-col class="ion-text-start">
		<img class="orderbird-logo" [hidden]="this.gastroService.$gastro.hideSplitLogo === true" (click)="navigateBackCallback()" [src]="'./../../assets/logos/orderbird/RGB_obLogo_blue.svg'">
	</ion-col>

	<ion-col 
		*ngIf="globalService.globals?.switches.profileSystem === true" 
		sizeMd="4" 
		sizeLg="4" 
		sizeSm="4" 
		sizeXs="4" 
		class="end-col"
	>
		<button 
			*ngIf="this.localizationService.availableLanguageCodes.length > 0"
			(click)="changeLanguageButtonCallback()"
			class="icon-button"
		>
			<ion-icon class="language-icon" name="earth-outline"></ion-icon>
		</button>
	</ion-col>
</ion-row>

<div 
	*ngIf="gastroService.$gastro.menuImage || gastroService.$gastro.logoImage"
	class="overlay2"
>
	<div *ngIf="gastroService.$gastro.templateConfig?.layout !== 'Switch'"
	class="test"
	[ngStyle]="{ 'background': gastroService.$gastro.menuImage ? 'transparent' : 'white', 'background-image': 'url(' + gastroService.$gastro.menuImage +')'}" 
	></div>
	<div
		*ngIf="gastroService.$gastro.logoImage"
		[ngClass]="{'switch': gastroService.$gastro.templateConfig?.layout === 'Switch', 'no-switch': gastroService.$gastro.templateConfig?.layout !== 'Switch'}"
		[ngStyle]="{'background-image': 'url(' + gastroService.$gastro.logoImage +')'}" class="logo-container"
	>
	</div>
</div>