import { GastroService } from "./../services/gastro.service";
import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { Gastro } from "src/shared/split-submodules/types/types";

@Injectable({ providedIn: "root" })
export class MenuAvailableGuard implements CanActivate {
	constructor(
    public gastroService: GastroService,
    public router: Router,
	) { }
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return new Promise(async(resolve) => {
			 this.gastroService.setGastroIdAndLoadGastro(this.gastroService.$gastroId).then((gastro:Gastro)=>{
				if (gastro.isMenuAvailable == true
				|| gastro.isMenuAvailable == undefined) {
					resolve(true);
					return;
				} else {
					this.router.navigate(["table-content"]);
					resolve(false);
					return;
				}
			});
		
		});

	}
}

